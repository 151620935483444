<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import config from "devextreme/core/config";
import ruMessages from "devextreme/localization/messages/ru.json";
import { locale, loadMessages } from "devextreme/localization";

config({
  forceIsoDateParsing: true,
});

export default {
  name: "App",
  created() {
    loadMessages(ruMessages);
    locale("ru-RU");
  },

};
</script>
