<template>
  <b-alert
    show
    :variant="resultInternal.successed ? 'success' : 'danger'"
    class="alert-dismissible alert-label-icon label-arrow fade show"
    role="alert"
    v-if="resultInternal.show"
  >
    <i class="ri-error-warning-line label-icon"></i>
    <strong>{{ resultInternal.message }}</strong>
    <ul class="my-0" v-if="errors.length > 0">
      <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
    </ul>
    <button type="button" class="btn-close" @click="this.resultInternal.show = false"></button>
  </b-alert>
</template>

<script>
export default {
  props: {
    result: {
      type: Object,
      default() {
        return { successed: false, message: "", errors: [], show: false };
      },
    },
  },
  computed: {
    errors: {
      get() {
        if (this.resultInternal.errors && this.resultInternal.errors.messages.length > 0) {
          return this.resultInternal.errors.messages;
        }
        return [];
      },
    },
  },
  data() {
    return {
      resultInternal: this.result,
    };
  },
  methods: {
    setResult(message, errors, isError) {
      this.resultInternal.message = message;
      this.resultInternal.errors = errors;
      this.resultInternal.show = true;
      this.resultInternal.successed = isError;
    },
  },
};
</script>

<style></style>
