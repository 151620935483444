import axios from "axios";
import { formatDate } from "devextreme/localization";

export const state = {
  isLoading: false,
  error: null,
  actualCharges: null,
  actualDebtsDetails: null,
  actualDebtsGrouped: null,
  actualPaymentsDetails: null,
  actualPaymentsGrouped: null,
  chargesHistoryDetails: null,
  debtsHistoryDetails: null,
  debtsHistoryGrouped: null,
  paymentsAndChargesHistory: null,
  paymentsHistoryDetails: null,
  paymentsHistoryGrouped: null,
};

export const getters = {
  debtsData(state) {
    var data = {
      actualDebtsGrouped: {
        debtsDetails: [],
        actualDate: "",
        debtsAll: 0,
        debtsOpened: 0,
        debtsClosed: 0,
        monthsAll: 0,
        monthsOpened: 0,
        monthsClosed: 0,
        periodsOpened: "",
        periodsClosed: "",
      },
      debtsHistoryGrouped: { debtsGrouped: [] },
    };

    if (state.actualDebtsGrouped) {
      if (state.actualDebtsGrouped.actualDate) {
        data.actualDebtsGrouped.actualDate = formatDate(new Date(state.actualDebtsGrouped.actualDate), "shortDate");
      }
      data.actualDebtsGrouped.debtsAll = state.actualDebtsGrouped.debtsOpened + state.actualDebtsGrouped.debtsClosed;
      data.actualDebtsGrouped.debtsOpened = state.actualDebtsGrouped.debtsOpened;
      data.actualDebtsGrouped.debtsClosed = state.actualDebtsGrouped.debtsClosed;
      data.actualDebtsGrouped.monthsClosed = state.actualDebtsGrouped.monthsClosed;
      data.actualDebtsGrouped.monthsOpened = state.actualDebtsGrouped.monthsOpened;
      data.actualDebtsGrouped.monthsAll = state.actualDebtsGrouped.monthsOpened + state.actualDebtsGrouped.monthsClosed;
      data.actualDebtsGrouped.periodsOpened = "\n" + state.actualDebtsGrouped.periodsOpened;
      data.actualDebtsGrouped.periodsClosed = "\n" + state.actualDebtsGrouped.periodsClosed;
      data.actualDebtsGrouped.debtsDetails = state.actualDebtsGrouped.actualDebts;
    }

    if (state.debtsHistoryGrouped) {
      data.debtsHistoryGrouped.debtsGrouped = state.debtsHistoryGrouped.debtsGrouped;
    }

    return data;
  },

  debtsAll(state) {
    if (state.actualDebtsGrouped) return state.actualDebtsGrouped.debtsOpened + state.actualDebtsGrouped.debtsClosed;
    return 0;
  },
  lastTenDebts(state, getters) {
    var list = [];
    var items = getters.debtsData.debtsHistoryGrouped.debtsGrouped;
    for (var i = 0; i < items.length; i++) {
      list.push({
        x: formatDate(new Date(items[i].actualDate), "shortDate"),
        y: (items[i].debtsOpened + items[i].debtsClosed).toFixed(2),
      });
    }
    list.push({
      x: getters.debtsData.actualDebtsGrouped.actualDate,
      y: getters.debtsData.actualDebtsGrouped.debtsAll.toFixed(2),
    });

    return [{ name: "Долг", data: list.splice(list.length - 10, 10) }];
  },

  paymentsData(state) {
    var data = {
      actualPaymentsGrouped: {
        paymentsDetails: [],
        actualMonth: "",
        sum: 0,
        periods: "",
      },
      paymentsHistoryGrouped: { paymentsGrouped: [] },
      actualPaymentsDetails: [],
      paymentsHistoryDetails: [],
      paymentsAndChargesHistory: [],
    };

    if (state.actualPaymentsGrouped) {
      if (state.actualPaymentsGrouped.actualMonth) {
        data.actualPaymentsGrouped.actualMonth = state.actualPaymentsGrouped.actualMonth;
      }
      data.actualPaymentsGrouped.sum = state.actualPaymentsGrouped.sum;
      data.actualPaymentsGrouped.periods = "\n" + state.actualPaymentsGrouped.periods;
    }

    if (state.paymentsHistoryGrouped) {
      data.paymentsHistoryGrouped.paymentsGrouped = state.paymentsHistoryGrouped.paymentsGrouped;
    }

    if (state.actualPaymentsDetails) {
      data.actualPaymentsDetails = state.actualPaymentsDetails;
    }
    if (state.paymentsHistoryDetails) {
      data.paymentsHistoryDetails = state.paymentsHistoryDetails;
    }
    if (state.paymentsAndChargesHistory) {
      data.paymentsAndChargesHistory = state.paymentsAndChargesHistory;
    }

    return data;
  },
  lastTenPayments(_state, getters) {
    var list = [];
    var items = getters.paymentsData.paymentsHistoryGrouped.paymentsGrouped;
    for (var i = 0; i < items.length; i++) {
      list.push({
        x: formatDate(new Date(items[i].actualMonth), "MM.yyyy"),
        y: items[i].sum.toFixed(2),
      });
    }
    let lastXMonth = formatDate(new Date(getters.paymentsData.actualPaymentsGrouped.actualMonth), "MM.yyyy");
    if (list.filter((r) => r.x == lastXMonth).length == 0)
      list.push({
        x: lastXMonth,
        y: getters.paymentsData.actualPaymentsGrouped.sum.toFixed(2),
      });
    return [{ name: "Оплата", data: list.splice(list.length - 10, 10) }];
  },
  paymentsMonth(_state, getters) {
    if (getters.paymentsData.actualPaymentsGrouped.actualMonth == "") return "";
    var month = new Intl.DateTimeFormat("ru-RU", {
      month: "long",
      year: "numeric",
    }).format(new Date(getters.paymentsData.actualPaymentsGrouped.actualMonth));
    return month;
  },
  chargesData(state) {
    var data = {
      actualMonth: null,
      incomeBalance: 0,
      constantCharges: 0,
      privilegeDiscount: 0,
      singleCharges: 0,
      currentCharges: 0,
      compensationSum: 0,
      outcomeBalance: 0,
    };
    if (state.actualCharges) {
      data.actualMonth = state.actualCharges.actualMonth;
      data.incomeBalance = state.actualCharges.incomeBalance;
      data.constantCharges = state.actualCharges.constantCharges;
      data.privilegeDiscount = state.actualCharges.privilegeDiscount;
      data.singleCharges = state.actualCharges.singleCharges;
      data.currentCharges = state.actualCharges.currentCharges;
      data.compensationSum = state.actualCharges.compensationSum;
      data.outcomeBalance = state.actualCharges.outcomeBalance;
    }
    return data;
  },

  error(state) {
    return state.error;
  },

  isLoading(state) {
    return state.isLoading;
  },
};
export const actions = {
  async load({ commit }, id) {
    commit("loadBegin");
    try {
      var response = await axios.get(`${process.env.VUE_APP_URL}/api/debtor-card/debts-payments/` + id);
      var data = response.data;
      commit("loadSuccess", {
        actualCharges: data.actualCharges,
        actualDebtsDetails: data.actualDebtsDetails,
        actualDebtsGrouped: data.actualDebtsGrouped,
        actualPaymentsDetails: data.actualPaymentsDetails,
        actualPaymentsGrouped: data.actualPaymentsGrouped,
        chargesHistoryDetails: data.chargesHistoryDetails,
        debtsHistoryDetails: data.debtsHistoryDetails,
        debtsHistoryGrouped: data.debtsHistoryGrouped,
        paymentsAndChargesHistory: data.paymentsAndChargesHistory,
        paymentsHistoryDetails: data.paymentsHistoryDetails,
        paymentsHistoryGrouped: data.paymentsHistoryGrouped,
      });
    } catch (error) {
      commit("loadFail", error);
      throw error;
    }
  },
};
export const mutations = {
  loadBegin(state) {
    state.isLoading = true;
  },
  loadSuccess(state, obj) {
    state.isLoading = false;
    state.actualCharges = obj.actualCharges;
    state.actualDebtsDetails = obj.actualDebtsDetails;
    state.actualDebtsGrouped = obj.actualDebtsGrouped;
    state.actualPaymentsDetails = obj.actualPaymentsDetails;
    state.actualPaymentsGrouped = obj.actualPaymentsGrouped;
    state.chargesHistoryDetails = obj.chargesHistoryDetails;
    state.debtsHistoryDetails = obj.debtsHistoryDetails;
    state.debtsHistoryGrouped = obj.debtsHistoryGrouped;
    state.paymentsAndChargesHistory = obj.paymentsAndChargesHistory;
    state.paymentsHistoryDetails = obj.paymentsHistoryDetails;
    state.paymentsHistoryGrouped = obj.paymentsHistoryGrouped;
  },
  loadFail(state, msg) {
    state.error = msg;
  },
};
