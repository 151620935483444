import axios from "axios";
import { defineStore } from "pinia";
import notify from "devextreme/ui/notify";

const getUrl = `${process.env.VUE_APP_URL}/api/debtor-card/work-logs/`;
const addUrl = `${process.env.VUE_APP_URL}/api/debtor-card/add-work-log`;
const updateUrl = `${process.env.VUE_APP_URL}/api/debtor-card/update-work-log`;
const deleteUrl = `${process.env.VUE_APP_URL}/api/debtor-card/delete-work-log`;

export const useDebtorLog = defineStore({
  id: "debtorLog",
  state: () => ({
    isLoading: false,
    error: [],

    debtorId: null,
    rows: [],
  }),
  getters: {
    currentWritState(state) {
      return state.writ.writStateId;
    },
  },
  actions: {
    resetError() {
      this.error = [];
    },
    async load(debtorId, { from, to, search, personalWorkOnly }) {
      this.resetError();
      this.loading = true;
      try {
        var response = await axios.get(getUrl + `?id=${debtorId}&from=${from}&to=${to}&personalWorkOnly=${personalWorkOnly}&search=${search}`);
        var data = response.data;
        this.debtorId = debtorId;
        this.rows = data;
      } catch (error) {
        this.error = [error];
        throw error;
      }
    },

    async add(debtorId, data) {
      try {
        this.resetError();
        this.isLoading = true;
        await axios.post(addUrl, { debtorId, ...data });

        notify({
          message: "Лог добавлен",
          height: 40,
          width: 120,
          minWidth: 120,
          position: {
            my: "bottom left",
            at: "bottom left",
            offset: '20 -20'
          },
          displayTime: 3500
        }, "success");

      } catch (error) {
        let response = error.response;
        if (response.data.errors) {
          // if exists error list
          var errors = response.data.errors;
          for (var key of Object.keys(errors)) {
            errors[key].map((e) => {
              this.error.push(e);
            });
          }

        } else {
          // if single error
          this.error.push({ message: response.data.title });
        }
        throw this.error;
      }
      this.isLoading = false;
    },

    async update(debtorId, data) {
      try {
        this.resetError();
        this.isLoading = true;
        await axios.put(updateUrl, { debtorId, ...data });

        notify({
          message: "Лог обновлен",
          height: 40,
          width: 120,
          minWidth: 120,
          position: {
            my: "bottom left",
            at: "bottom left",
            offset: '20 -20'
          },
          displayTime: 3500
        }, "success");

      } catch (error) {
        let response = error.response;
        if (response.data.errors) {
          // if exists error list
          var errors = response.data.errors;
          for (var key of Object.keys(errors)) {
            errors[key].map((e) => {
              this.error.push(e);
            });
          }

        } else {
          // if single error
          this.error.push({ message: response.data.title });
        }
        throw this.error;
      }
      this.isLoading = false;
    },

    async delete(debtorId, id) {
      try {
        this.resetError();
        this.isLoading = true;
        await axios.delete(deleteUrl + `?debtorId=${debtorId}&id=${id}`);

        notify({
          message: "Лог удален",
          height: 40,
          width: 120,
          minWidth: 120,
          position: {
            my: "bottom left",
            at: "bottom left",
            offset: '20 -20'
          },
          displayTime: 3500
        }, "success");

      } catch (error) {
        let response = error.response;
        if (response.data.errors) {
          // if exists error list
          var errors = response.data.errors;
          for (var key of Object.keys(errors)) {
            errors[key].map((e) => {
              this.error.push(e);
            });
          }

        } else {
          // if single error
          this.error.push({ message: response.data.title });
        }
        throw this.error;
      }
      this.isLoading = false;
    },
  },
});
