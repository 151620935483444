<template>
  <DxDateBox
    displayFormat="monthAndYear"
    :calendarOptions="{ maxZoomLevel: 'year', minZoomLevel: 'century' }"
    dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
  >
    <slot></slot>
  </DxDateBox>
</template>

<script>
export default {};
</script>

<style></style>
