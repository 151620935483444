import axios from "axios";

export const state = {
  isLoading: false,
  error: null,

  debtorId: null,
  writ: {
    preparationDate: new Date(),
    preparationTemplateId: null,
    preparationDocumentId: null,
    preparationApplicationFormId: 1,
    preparationCourtNumber: null,
    preparationPeriodStart: null,
    preparationPeriodEnd: null,
    preparationPeriodDetails: null,
    preparationSumToCollect: null,
    preparationStateFee: null,
    preparationPenalties: null,
    preparationNote: null,
    writCourtStateFeeDetails: null,
    filingDate: null,
    filingReturnDate: null,
    refilingDate: null,
    courtDecisionExecutionDocNo: null,
    courtDecisionType: null,
    courtDecisionTypeId: null,
    courtDecisionDate: null,
    courtDecisionIsReturn: false,
    courtDecisionIsCancel: false,
    courtDecisionReturnDate: null,
    courtDecisionCancelDate: null,
    courtDecisionSum: null,
    courtDecisionStateFee: null,
    courtDecisionReceivingExecDocDate: null,
    courtDecisionReceivingDate: null,
    courtDecisionComment: null,
    closeDate: null,
    closeReason: null,
    isClosed: false,
    isArchived: false,
    preparationRespondents: [],
    executionDocuments: [],
    files: [],
    writState: 1,
  }
};

export const getters = {
  debtorId(state) {
    return state.debtorId;
  },

  writData(state) {
    return state.writ;
  },

  error(state) {
    return state.error;
  },

  isLoading(state) {
    return state.isLoading;
  },
};

export const actions = {
  async new({ commit }, debtorId) {
    commit("loadBegin");
    try {
      commit("loadSuccess", { debtorId: debtorId });
    } catch (error) {
      commit("loadFail", error);
      throw error;
    }
  },

  async load({ commit }, id) {
    commit("loadBegin");
    try {
      var response = await axios.get(`${process.env.VUE_APP_URL}/api/debtor-card/debtor-info/` + id);
      var data = response.data;
      commit("loadSuccess", { debtorId: data.debtorId, writ: data });
    } catch (error) {
      commit("loadFail", error);
      throw error;
    }
  },

  // async save({ commit, state }) {
    // try {
    //   let id = state.debtor.id;
    //   // await axios.post(`${process.env.VUE_APP_URL}/api/debtor-card/add-notes/`, { debtorId: id, notes: notes });
    // } catch (error) {
    //   commit("loadFail", error);
    //   throw error;
    // }
  // },


  async generatePreparationDocument({ commit, state }) {
    await this.save({ commit, state });
    var response = await axios.post(`${process.env.VUE_APP_URL}/api/writs/generate-preparation-document/${this.idInternal}`, {
      id: this.idInternal,
    });
    commit("preparationDocumentSuccess", response.data);
  },

  async generateExecutionDocument({ commit, state }) {
    await this.save({ commit, state });
    var response = await axios.post(`${process.env.VUE_APP_URL}/api/writs/generate-preparation-document/${this.idInternal}`, {
      id: this.idInternal,
    });
    commit("executionDocumentSuccess", response.data);
  }

};
export const mutations = {
  loadBegin(state) {
    state.isLoading = true;
  },
  loadSuccess(state, obj) {
    state.isLoading = false;
    state.debtorId = obj.debtorId;
    if (obj.writ) {
      state.writ = obj.writ;
    }
  },
  loadFail(state, msg) {
    state.error = msg;
  },
  preparationDocumentSuccess(state, obj) {
    state.writ.preparationDocumentId = obj.id;
    state.writ.preparationDocument = obj;
  },

  executionDocumentSuccess(state, obj) {
    state.writ.preparationDocumentId = obj.id;
    state.writ.preparationDocument = obj;
  }

};
