import axios from "axios";
import { authHeader } from "./auth-header";
const API_URL = process.env.VUE_APP_URL + "/api/auth/";

export const userService = {
  login,
  logout,
  register,
  getAll,
};

function login(username, password) {
  return axios
    .post(API_URL + "login", { username, password })
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("user", JSON.stringify(user));
      }
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
}

function register(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };
  return fetch(API_URL + `register`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };
  return fetch(API_URL + `/users`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  const data = response.data;
  if (response.status === 401) {
    // auto logout if 401 response returned from api
    logout();
    location.reload(true);
    const error = (data && data.message) || response.statusText;
    return Promise.reject(error);
  }
  return data;
}
