import axios from "axios";
import { formatDate } from "devextreme/localization";

export const state = {
  isLoading: false,
  error: null,
  actualPenaltiesDetails: null,
  actualPenaltiesGrouped: null,
  penaltiesHistoryGrouped: null,
};

export const getters = {
  penaltiesData(state) {
    var data = {
      actualPenaltiesGrouped: {
        actualMonth: "",
        penaltiesBilled: 0,
        penaltiesCharged: 0,
        penaltiesChargedSocial: 0,
        penaltiesChargedOverhaul: 0,
        penaltiesReceived: 0,
        penaltiesReceivedSocial: 0,
        penaltiesReceivedOverhaul: 0,
      },
      penaltiesHistoryGrouped: [],
    };

    if (state.actualPenaltiesGrouped) {
      if (state.actualPenaltiesGrouped.actualMonth) {
        data.actualPenaltiesGrouped.actualMonth = formatDate(new Date(state.actualPenaltiesGrouped.actualMonth), "shortDate");
      }
      data.actualPenaltiesGrouped.actualMonth = state.actualPenaltiesGrouped.actualMonth;
      data.actualPenaltiesGrouped.penaltiesBilled = state.actualPenaltiesGrouped.penaltiesBilled;
      data.actualPenaltiesGrouped.penaltiesCharged = state.actualPenaltiesGrouped.penaltiesCharged;
      data.actualPenaltiesGrouped.penaltiesChargedSocial = state.actualPenaltiesGrouped.penaltiesChargedSocial;
      data.actualPenaltiesGrouped.penaltiesChargedOverhaul = state.actualPenaltiesGrouped.penaltiesChargedOverhaul;
      data.actualPenaltiesGrouped.penaltiesReceived = state.actualPenaltiesGrouped.penaltiesReceived;
      data.actualPenaltiesGrouped.penaltiesReceivedSocial = state.actualPenaltiesGrouped.penaltiesReceivedSocial;
      data.actualPenaltiesGrouped.penaltiesReceivedOverhaul = state.actualPenaltiesGrouped.penaltiesReceivedOverhaul;
    }

    if(state.penaltiesHistoryGrouped) {
      data.penaltiesHistoryGrouped = state.penaltiesHistoryGrouped;
    }

    return data;
  },

  lastTenPenalties(_state, getters) {
    var list = [];
    var items = getters.penaltiesData.penaltiesHistoryGrouped;
    for (var i = 0; i < items.length; i++) {
      list.push({
        x: formatDate(new Date(items[i].actualMonth), "MM.yyyy"),
        y: items[i].penaltiesBilled.toFixed(2),
      });
    }
    if (getters.penaltiesData.actualPenaltiesGrouped.actualMonth != "") {
      let lastXMonth = formatDate(new Date(getters.penaltiesData.actualPenaltiesGrouped.actualMonth), "MM.yyyy");
        list.push({
          x: lastXMonth,
          y: getters.penaltiesData.actualPenaltiesGrouped.penaltiesBilled.toFixed(2),
        });

    }
    return [{ name: "Пени", data: list.splice(list.length - 10, 10) }];
  },

  penaltiesMonth(_state, getters) {
    if (getters.penaltiesData.actualPenaltiesGrouped.actualMonth == "") return "";
    var month = new Intl.DateTimeFormat("ru-RU", {
      month: "long",
      year: "numeric",
    }).format(new Date(getters.penaltiesData.actualPenaltiesGrouped.actualMonth));
    return month;
  },
  error(state) {
    return state.error;
  },

  isLoading(state) {
    return state.isLoading;
  },
};
export const actions = {
  async load({ commit }, id) {
    commit("loadBegin");
    try {
      var response = await axios.get(`${process.env.VUE_APP_URL}/api/debtor-card/penalties/` + id);
      var data = response.data;
      commit("loadSuccess", {
        actualPenaltiesGrouped: data.actualPenaltiesGrouped,
        penaltiesHistoryGrouped: data.penaltiesHistoryGrouped,
      });
    } catch (error) {
      commit("loadFail", error);
      throw error;
    }
  },
};
export const mutations = {
  loadBegin(state) {
    state.isLoading = true;
  },
  loadSuccess(state, obj) {
    state.isLoading = false;
    state.actualPenaltiesGrouped = obj.actualPenaltiesGrouped;
    state.penaltiesHistoryGrouped = obj.penaltiesHistoryGrouped;
  },
  loadFail(state, msg) {
    state.error = msg;
  },
};
