import { userService } from "../../helpers/authservice/user.service";
import router from "../../router/index";

const user = JSON.parse(localStorage.getItem("user"));
export const state = user ? { status: { loggeduser: true }, user } : { status: {}, user: null };

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state) {
    return state.status.loggeduser;
  },
  loggedUser(state) {
    return state.user;
  },

  rights(state) {
    if (state.user && state.user.rights) return state.user.rights;
    return [];
  },


  hasRightReports(_state, getters) { return getters.rights.includes(1); },
  hasRightReferences(_state, getters) { return getters.rights.includes(2); },
  hasRightData(_state, getters) { return getters.rights.includes(3); },
  hasRightSettings(_state, getters) { return getters.rights.includes(4); },
  hasRightExport(_state, getters) { return getters.rights.includes(5); },
  hasRightAddEditWrit(_state, getters) { return getters.rights.includes(6); },
  hasRightAddEditAgreement(_state, getters) { return getters.rights.includes(7); },
  hasRightAddEditInhabitant(_state, getters) { return getters.rights.includes(8); },
  hasRightAddEditDebtorNotes(_state, getters) { return getters.rights.includes(9); },
  hasRightAddEditDebtorWorkLogs(_state, getters) { return getters.rights.includes(10); },
};

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  logIn({ dispatch, commit }, { username, password }) {
    commit("loginRequest", { username });

    return userService.login(username, password)
      .then((user) => {
        commit("loginSuccess", user);
        router.push("/debtors");
      },
        (error) => {
          dispatch("logout");
          commit("loginFailure", error);
          // dispatch("notification/error", error, { root: true });
          dispatch("notification/error", 'Неправильные имя пользователя и/или пароль', { root: true });
        }
      );
  },
  // Logout the user
  logOut({ commit }) {
    userService.logout();
    commit("logout");
  },

  // eslint-disable-next-line no-unused-vars
  validate({ commit, state }) {
    if (state.user) {
      let date = new Date(state.user.expiration);
      date.setMinutes(date.getMinutes() - 5);
      if (new Date() > date) {
        commit("logout");
        return Promise.resolve(false);
      }
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  },
  // register the user
  register({ dispatch, commit }, user) {
    commit("registerRequest", user);
    return userService.register(user).then(
      (user) => {
        commit("registerSuccess", user);
        dispatch("notification/success", "Registration successful", {
          root: true,
        });
        router.push("/login");
      },
      (error) => {
        commit("registerFailure", error);
        dispatch("notification/error", error, { root: true });
      }
    );
  },
};

export const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggeduser: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  },
};
