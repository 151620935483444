import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.generic.debts.processor.css";
import "@/assets/css/site.css?v=1.1";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import AOS from "aos";
import "aos/dist/aos.css";
import i18n from "./i18n";
import store from "./state/store";

import BootstrapVue3 from "bootstrap-vue-3";
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import Maska from "maska";

import VueFeather from "vue-feather";
import Particles from "particles.vue3";

import "@/assets/scss/config/saas/app.scss";
import "@vueform/slider/themes/default.css";

import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import DxButton from "devextreme-vue/button";
import DxSelectBox from "devextreme-vue/select-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxDateBox from "devextreme-vue/date-box";
import DxTextBox from "devextreme-vue/text-box";
import DxNumberBox from "devextreme-vue/number-box";
import MonthBox from "@/components/controls/month-box";
import AlertBox from "@/components/controls/alert-box";

import {
  DxDataGrid,
  DxEditing,
  DxSelection,
  DxPager,
  DxPaging,
  DxFilterRow,
  DxScrolling,
  DxColumn,
  DxFormat,
  DxLookup,
} from "devextreme-vue/data-grid";
import { authHeader } from "@/helpers/authservice/auth-header";
import { DxPopup, DxPosition, DxToolbarItem } from "devextreme-vue/popup";

import axios from "axios";
import { createPinia } from 'pinia'

const pinia = createPinia()

axios.interceptors.request.use(
  (config) => {
    config.headers = authHeader();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

import DateBox from "devextreme/ui/date_box";
import config from "devextreme/core/config";
config({
  forceIsoDateParsing: true,
})

DateBox.defaultOptions({
  options: {
    dateSerializationFormat: "yyyy-MM-ddTHH:mm:ss"
  }
});

AOS.init({
  easing: "ease-out-back",
  duration: 1000,
});

createApp(App)
  .use(store)
  .use(router)
  .use(VueApexCharts)
  .use(BootstrapVue3)
  .use(pinia)
  .component(VueFeather.type, VueFeather)
  //common
  .component("DxValidator", DxValidator)
  .component("DxRequiredRule", DxRequiredRule)

  .component("DxLoadPanel", DxLoadPanel)
  .component("DxButton", DxButton)
  .component("DxSelectBox", DxSelectBox)
  .component("DxCheckBox", DxCheckBox)
  .component("DxDateBox", DxDateBox)
  .component("DxTextBox", DxTextBox)
  .component("DxNumberBox", DxNumberBox)
  // <
  // grid
  .component("DxDataGrid", DxDataGrid)
  .component("DxEditing", DxEditing)
  .component("DxColumn", DxColumn)
  .component("DxSelection", DxSelection)
  .component("DxPager", DxPager)
  .component("DxPaging", DxPaging)
  .component("DxFilterRow", DxFilterRow)
  .component("DxScrolling", DxScrolling)
  .component("DxFormat", DxFormat)
  .component("DxLookup", DxLookup)
  //<
  // popup
  .component("DxPopupModal", DxPopup)
  .component("DxPopupPosition", DxPosition)
  .component("DxPopupToolbarItem", DxToolbarItem)
  // <
  // custom controls
  .component("MonthBox", MonthBox)
  .component("AlertBox", AlertBox)
  // <
  .use(Maska)
  .use(Particles)
  .use(i18n)
  .use(vClickOutside)
  .mount("#app");
