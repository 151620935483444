import axios from "axios";
import { formatDate } from "devextreme/localization";

export const state = {
    isLoading: false,
    error: null,
    debtor: null,
    house: null,
};

export const getters = {
    debtorData(state) {
        return { debtor: state.debtor, house: state.house };
    },

    title(state, getters) {
        if (!state.debtor) return "";

        var debtorData = state.debtor;
        var accountNumber = `ЛС ${debtorData.accountNumber}`;
        if (debtorData.oldAccountNumber) {
            accountNumber = accountNumber + ` (${debtorData.oldAccountNumber})`;
        }

        var isCommunal = "";
        if (debtorData.isCommunal != null) isCommunal = "<span class='px-2'>|</span>" + (debtorData.isCommunal ? "коммунальная" : "отдельная");
        var title = `${accountNumber}<span class="px-2">|</span>${getters.address} кв.${debtorData.flatNumber}<span class="px-2">|</span>
        ${debtorData.ownerName}<span class="px-2">|</span>${debtorData.flatType}${isCommunal}`.replace("null", " не задано ");

        return title;
    },

    address(state) {
        if (!state.house) return null;
        return state.house.address;
    },

    debtorNotes(state) {
        if (!state.debtor) return null;
        return state.debtor.notes;
    },

    writsArchivedCount(state) {
        if (!state.debtor) return 0;
        return state.debtor.writsArchivedCount;
    },

    error(state) {
        return state.error;
    },

    isLoading(state) {
        return state.isLoading;
    },
};
export const actions = {
    async load({ commit }, id) {
        commit("loadBegin");
        try {
            var response = await axios.get(`${process.env.VUE_APP_URL}/api/debtor-card/debtor-info/` + id);
            var data = response.data;
            commit("loadSuccess", { debtor: data.debtor, house: data.house });
        } catch (error) {
            commit("loadFail", error);
            throw error;
        }
    },

    async addNotes({ commit, state }, notes) {
        try {
            let id = state.debtor.id;
            await axios.post(`${process.env.VUE_APP_URL}/api/debtor-card/add-notes/`, { debtorId: id, notes: notes });
            commit("notesSuccess", notes);
        } catch (error) {
            commit("loadFail", error);
            throw error;
        }
    },

    async getSummaryReport({ state }) {
        let id = state.debtor.id;

        axios
            .get(`${process.env.VUE_APP_URL}/api/debtor-card/summary/` + id, { responseType: "arraybuffer" })
            .then(function(response) {
                var headers = response.headers;
                var blob = new Blob([response.data], { type: headers["content-type"], });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                const now = formatDate(new Date(), "dd.MM.yyyy_HH_mm");
                const fileName = `Справка по ЛС ${state.debtor.accountNumber} от ${now}.xlsx`;
                link.download = fileName;
                link.click();
            });
    }
};
export const mutations = {
    loadBegin(state) {
        state.isLoading = true;
    },
    loadSuccess(state, obj) {
        state.isLoading = false;
        state.debtor = obj.debtor;
        state.house = obj.house;
    },
    notesSuccess(state, notes) {
        state.debtor.notes = notes;
    },
    loadFail(state, msg) {
        state.error = msg;
    },
};
