import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Логин",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "Главная",
      authRequired: true,
    },
    component: () => import("../views/index.vue"),
  },
  {
    path: "/companies",
    name: "companies",
    meta: {
      title: "Компании",
      authRequired: true,
    },
    component: () => import("../views/companies/index.vue"),
  },

  {
    path: "/debtors",
    name: "debtors",
    meta: {
      title: "Должники",
      authRequired: true,
    },
    component: () => import("../views/debtors/index.vue"),
  },
  {
    path: "/registries",
    name: "debtors-registries",
    meta: {
      title: "Рабочие реестры",
      authRequired: true,
    },
    component: () => import("../views/debtors-registries/index.vue"),
  },
  {
    path: "/writs",
    name: "writs",
    meta: {
      title: "Журнал исков",
      authRequired: true,
    },
    component: () => import("../views/writs/index.vue"),
  },
  {
    path: "/debtor-card/:id",
    name: "debtor-card",
    props: true,
    meta: {
      title: "Карточка должника",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (parseInt(routeTo.params.id)) {
          next();
        } else {
          next({ name: "404" });
        }
      },
    },
    component: () => import("../views/debtor-card/index.vue"),
  },
  {
    path: "/writ-card/:id/debtorId/:debtorId",
    name: "writ-card",
    props: true,
    meta: {
      title: "Карточка иска",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (routeTo.params.id == 0 || parseInt(routeTo.params.id)) {
          next();
        } else {
          next({ name: "404" });
        }
      },
    },
    component: () => import("../views/writ-card/index.vue"),
  },
  {
    path: "/data/houses",
    name: "data-houses",
    meta: {
      title: "Дома",
      authRequired: true,
      checkRight: "hasRightReferences"
    },
    component: () => import("../views/data/houses.vue"),
  },
  {
    path: "/data/bailiffs",
    name: "data-bailiffs",
    meta: {
      title: "Службы судебных приставов",
      authRequired: true,
      checkRight: "hasRightReferences"
    },
    component: () => import("../views/data/bailiffs.vue"),
  },
  {
    path: "/data/courts",
    name: "data-courts",
    meta: {
      title: "Судебные участки",
      authRequired: true,
      checkRight: "hasRightReferences"
    },
    component: () => import("../views/data/courts.vue"),
  },
  {
    path: "/data/templates",
    name: "data-templates",
    meta: {
      title: "Печатные формы",
      authRequired: true,
      checkRight: "hasRightReferences"
    },
    component: () => import("../views/data/templates.vue"),
  },
  {
    path: "/import",
    name: "import",
    meta: {
      title: "Обмен данными",
      authRequired: true,
      checkRight: "hasRightData"
    },
    component: () => import("../views/import/index.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    meta: {
      title: "Отчеты",
      authRequired: true,
      checkRight: "hasRightReports"
    },
    component: () => import("../views/reports/index.vue"),
  },
  {
    path: "/admin/users",
    name: "users",
    meta: {
      title: "Пользователи",
      authRequired: true,
      checkRight: "hasRightSettings"
    },
    component: () => import("../views/admin/users.vue"),
  },
  {
    path: "/admin/roles",
    name: "roles",
    meta: {
      title: "Роли",
      authRequired: true,
      checkRight: "hasRightSettings"
    },
    component: () => import("../views/admin/roles.vue"),
  },


  // > errors
  { path: "/:pathMatch(.*)*", component: () => import("../views/errors/404.vue") },
  { path: "/errors/404", name: "404", meta: { title: "Страница не найдена" }, component: () => import("../views/errors/404.vue") },
  // < errors

  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch("auth/logOut");

        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) => route.push("/"));
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom });
      },
    },
    component: () => import("../views/auth/logout/basic"),
  },

];
