const filters = JSON.parse(localStorage.getItem("debtors.filters"));
export const state = { filters: filters };

export const mutations = {
  save(state, filters) {
    state.filters = filters;
    localStorage.setItem("debtors.filters", JSON.stringify(filters));
  },
};

export const actions = {
  saveFilters({ commit }, { filters }) {
    commit("save", filters);
  },
};
